import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const CustomTextField = ({
  name,
  placeholder,
  label,
  id,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  type,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  if (label === "Password") {
    return (
      <TextField
        name={name}
        placeholder={placeholder}
        label={label}
        id={id}
        sx={{ mb: 3 }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error} // Already passed as a boolean
        helperText={helperText} // Already passed as a string
        type={showPassword ? "text" : type}
        fullWidth
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    );
  }

  return (
    <TextField
      name={name}
      placeholder={placeholder}
      label={label}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      error={error} // Already passed as a boolean
      helperText={helperText} // Already passed as a string
      fullWidth
      size="small"
      sx={{ mb: 3 }}
      variant="outlined"
      {...rest}
    />
  );
};
