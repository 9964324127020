import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { CustomButton, CustomTextField } from "../../components";
import { useNavigate } from "react-router-dom";

export function ForgotPassword() {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(`${API_URL}create/admin/login`, values);
      const { data, type, message } = response.data;

      if (type === "error") {
        // Show the error message and store the snackbar key
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
      }

      if (data) {
        // // Dismiss the error snackbar if it exists
        // closeSnackbar(snackbarKey);
        // // Redirect to /authenticator route
        // navigate("/authenticator", {
        //   state: {
        //     qrCodeDataURL: data.qrCodeDataURL,
        //     otpSecret: data.otpSecret,
        //     userId: data.loginUser._id,
        //     token: `Bearer ${data.token}`,
        //     user: JSON.stringify(data?.loginUser),
        //     isQRScanned: data?.isQRScanned,
        //     changeMFA: false,
        //   },
        // });
      }
    } catch (error) {
      console.error("Error during login:", error);
      enqueueSnackbar("Login failed. Please try again.", { variant: "error" });
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box sx={{ position: "absolute", right: "10px", top: 0 }}>
        <img style={{ width: "120px" }} src={logo} alt="Logo" />
      </Box>
      {/* Full viewport height */}

      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%", // Match parent height
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px", // Optional: Add padding for better appearance
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 500,
              mb: 3,
              textAlign: "left",
            }}
          >
            Forgot Password !
          </Typography>
          <CustomTextField
            name="email"
            placeholder="Enter Your Email"
            label="Email"
            id="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={false} // Replace with actual validation error logic
            helperText="" // Replace with actual validation error messages
          />
          <Typography
            onClick={() => navigate("/login")}
            sx={{
              cursor: "pointer",
              fontWeight: 500,
              textAlign: "right",
              mb: 2,
            }}
            variant="body2"
            color="error"
          >
            Back to login
          </Typography>
          <CustomButton type="submit">Forgot Password</CustomButton>
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        sx={{
          display: { xs: "none", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%", // Match parent height
          boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={login} alt="Login" style={{ width: "70%" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
