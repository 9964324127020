import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { CustomButton, CustomTextField } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";

export function FASetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminId } = location.state || {};
  const initialValues = {
    code: "",
  };

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${API_URL}create/admin/verify-login-otp`,
        {
          adminId,
          otp: values.code,
        }
      );
      const { data, type, message } = response.data;
      if (type === "error") {
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
      }
      if (type === "success") {
        enqueueSnackbar(message, {
          variant: type,
        });
        console.log(data);
        localStorage.setItem("pqckleToken", data.token);
        localStorage.setItem("pqckleAdmin", JSON.stringify(data.loggedUser));
        // Redirect to the dashboard or another page after successful verification
        navigate("/app/dashboard");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      enqueueSnackbar("OTP verification failed. Please try again.", {
        variant: "error",
      });
    }
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Required!"),
  });

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box sx={{ position: "absolute", right: "10px", top: 0 }}>
        <img style={{ width: "120px" }} src={logo} alt="Logo" />
      </Box>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 500,
              mb: 3,
              textAlign: "left",
            }}
          >
            Verify 2FA
          </Typography>
          <form onSubmit={handleSubmit}>
            <CustomTextField
              name="code"
              placeholder="Enter Your code"
              label="Code"
              id="code"
              type="number"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.code && !!errors.code}
              helperText={touched.code && errors.code}
            />
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: 500,
                textAlign: "right",
                mb: 2,
              }}
              variant="body2"
            >
              Didn't receive the code?{" "}
              <span style={{ fontWeight: "bold" }}>Resend</span>
            </Typography>
            <CustomButton type="submit">Verify</CustomButton>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={login} alt="Login" style={{ width: "70%" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
