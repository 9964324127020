import React from "react";
import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  useTheme,
  List,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { DashboardRounded } from "@mui/icons-material";
import logo from "../assets/logo.jpeg";

export function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const theme = useTheme();

  const navLinks = [
    {
      label: "Home",
      route: "/app/dashboard",
      icon: <DashboardRounded />,
    },
    {
      label: "Services",
      route: "/app/services",
      icon: <DashboardRounded />,
    },
    {
      label: "Leads",
      route: "/app/leads",
      icon: <DashboardRounded />,
    },
    {
      label: "Requesters",
      route: "/app/requesters",
      icon: <DashboardRounded />,
    },
    {
      label: "Buyers",
      route: "/app/buyers",
      icon: <DashboardRounded />,
    },
  ];

  return (
    <Drawer
      className="custom-scrollbar"
      variant="permanent"
      sx={{
        borderRight: "1px solid rgba(69, 190, 235, 1)",
        boxShadow: "0px 4px 4px 0px rgba(69, 190, 235, 1)",
        width: 250,

        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 300,
          boxSizing: "border-box",
          background: theme.palette.primary.main,
        },
      }}
    >
      <Grid sx={{ textAlign: "center" }}>
        <img style={{ width: "100px" }} src={logo} alt="Logo" />
      </Grid>
      <Box sx={{ padding: "0 36px", marginTop: "6px" }}>
        <Typography
          sx={{
            color: "rgba(69, 190, 235, 1)",
            fontSize: "13px",
            fontWeight: 600,
          }}
        >
          SERVICES
        </Typography>
        <List>
          {navLinks.map((link) => {
            const isActive =
              location.pathname.includes(link.route) ||
              (link.route2 && location.pathname.includes(link.route2)) ||
              (link.route === "/orders" &&
                location.pathname.includes("/order-detail")) ||
              (link.route === "/clients" &&
                location.pathname.includes("/client-detail")) ||
              (link.route === "/drivers" &&
                location.pathname.includes("/driver-detail"));
            return (
              <ListItem
                key={link.label}
                disablePadding
                sx={{
                  background: isActive
                    ? "rgba(69, 190, 235, 1)"
                    : "rgba(69, 190, 235, 0.1)",
                  marginBottom: "2px",
                  borderRadius: "4px",
                }}
              >
                <ListItemButton
                  sx={{ padding: "2px 10px" }}
                  onClick={() => {
                    navigate(link.route);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: "18px",
                      minWidth: "34px",
                      color: isActive ? "#FFFFFF" : "rgba(69, 190, 235, 1)",
                    }}
                  >
                    {link.icon}
                    {/* {isActive
                      ? link.iconWhite
                      : mode == "dark"
                      ? link.iconWhite
                      : link.icon} */}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: isActive ? "#FFFFFF" : "rgba(69, 190, 235, 1)",
                    }}
                    primary={link.label}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
