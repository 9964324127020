import React from "react";
import { Box, Button, Typography } from "@mui/material";
import comingSoonBg from "../assets/comingSoonBg.png";
import comingSoon from "../assets/coming.png";
import logo from "../assets/logo.jpeg";

export function ComingSoon() {
  const handleContactClick = () => {
    window.open("https://pqxel.com/contact/request-services/", "_blank");
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Background image */}
      <Box
        component="img"
        src={comingSoonBg}
        alt="Background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the image covers the whole container
          objectPosition: "center top", // Keeps the top part visible and adjusts from there
          zIndex: -1,
        }}
      />
      <Box
        component="img"
        src={logo}
        alt="Logo"
        sx={{
          position: "absolute",
          top: 0,
          left: "20px",
          width: 100, // Adjust size as needed
        }}
      />
      {/* Center content */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: "50%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            color: "#000",
            fontSize: { xs: "16px", lg: "32px" },
            fontFamily: "Poppins",
            mb: 1,
          }}
        >
          WE ARE COMING SOON!!
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            color: "rgba(130, 130, 130, 1)",
            fontSize: { xs: "12px", lg: "16px" },
            fontFamily: "Poppins",
          }}
        >
          Stay tuned for something amazing
        </Typography>
        <Box
          component="img"
          src={comingSoon}
          alt="Coming Soon"
          sx={{
            width: { xs: 200, lg: 400 }, // Adjust size as needed
            margin: "16px 0",
          }}
        />

        {/* Contact Us Button */}
        <Box
          sx={{
            mt: 2, // Add top margin to create space between image and button
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#02B2D6",
              color: "#fff",
              fontWeight: 600,
              fontSize: "16px",
              padding: "8px 150px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#02B2D6",
              },
            }}
            onClick={handleContactClick}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      {/* Copyright text */}
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: "rgba(130, 130, 130, 1)",
            fontSize: "16px",
            fontFamily: "Poppins",
          }}
        >
          © Copyright Pqckle | All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
}
