import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Footer, Header, Sidebar } from "../components";
import { Home, LoginPage, ComingSoon, ForgotPassword, FASetup } from "../pages";
import { Box, Grid } from "@mui/material";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route for Login Page */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <ComingSoon />
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/authenticator"
          element={
            <PublicRoute>
              <FASetup />
            </PublicRoute>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <Grid container sx={{ height: "100vh" }}>
                <Grid item sx={{ width: 300 }}>
                  <Sidebar />
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Header />
                    <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                      <Routes>
                        <Route
                          index
                          element={<Navigate replace to="dashboard" />}
                        />
                        <Route path="dashboard" element={<Home />} />
                      </Routes>
                    </Box>
                    <Footer />
                  </Box>
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
